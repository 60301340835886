//BREAKPOINTS
$extra-small: 350px;
$small-mobile: 400px;
$mobile: 500px;
$mobile-xl: 600px;
$tablet: 768px;
$large-tablet: 850px;
$desktop: 1000px;
$mid-desktop: 1200px;
$extra-large: 1400px;
$xx-large: 1600px;
$xxx-large: 1800px;

//FONT STYLING
$cursive: cursive;

//COLORS
$primary-blue: #73ddff;
$default: #b6a7a7;
$light: #dbdbdb;
$darkgrey: #909090;
$grey: rgba(240, 234, 214, 0.5);
$white: #fffbfb;
$black: #34323d;
$blue: #343246;
$aqua: #1b3633;
$dark-aqua: #0e322e;
$hover: #005148;
$chip: rgba(240, 234, 214, 0.15);
$overlay: rgba(6, 1, 1, 0.2);
$sunshine: rgb(253, 128, 36);
$red: #ff0000;
$coral: #F88379;
$tan: #f5e6db;
$burnt: #BC9384;