.grocery-list-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grocery-list-modal {
    background: $aqua;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100vh;
    width: unquote("min(85vw, 450px)");
    z-index: 3;
    padding: 24px;
    &.opened {
        left: 0;
        animation: in 0.75s ease-in-out;
    }
    &.closed {
        left: -100%;
        animation: out 0.75s ease-in-out;
    }

    @media only screen and (hover: none) and (pointer: coarse) {
        height: calc(100vh - 108px);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-modal-header-container {
            display: flex;
            align-items: center;
            column-gap: 16px;

            .settings-img {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        .close {
            background: none;
            border: none;
            color: $white;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    .grocery-list {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-top: 16px;
        max-height: calc(100vh - 120px);
        overflow-y: auto;
        scrollbar-width: none;
        padding-bottom: 100px;

        .category-ingredient-container {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            .ingredient-category {
                font-size: 12px;
                color: $primary-blue;
            }

            .ingredient-date {
                font-size: 12px;
                color: $sunshine;
            }
        }

        .grocery-list-item {
            display: flex;
            align-items: flex-start;
            row-gap: 8px;
            column-gap: 8px;
            width: 100%;

            input[type="checkbox"] {
                min-height: 20px;
                min-width: 20px;
            }

            .grocery-list-item-detail-container {
                display: flex;
                flex-direction: column;
                row-gap: 6px;
                width: calc(100% - 28px);

                label {
                    &.checked {
                        color: $default;
                        text-decoration: line-through;
                        font-size: 16px;
                        width: 100%;
                    }
                }

                .recipe-name {
                    font-size: 12px;
                    color: $default;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    span {
                        display: inline-block;
                    }
                }

                .ingredient-category {
                    color: $primary-blue;
                }
            }

            .grocery-input-container {
                display: flex;
                align-items: center;
                column-gap: 8px;
                justify-content: space-between;

                .edit-icon {
                    height: 12px;
                    cursor: pointer;
                }
            }

            input[type="text"] {
                background: none;
                outline: none;
                border: none;
                color: $white;
                width: 100%;
                font-size: 16px;
            }

            .recipe-category {
                font-size: 12px;
                color: $primary-blue;
            }

            .recipe-date {
                font-size: 12px;
                color: $sunshine;
            }
        }
    }

    .meal-planning {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-top: 16px;
        max-height: calc(100vh - 120px);
        overflow-y: auto;
        scrollbar-width: none;

        .meal-plan-container {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            .meal-plan-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 8px;

                .meal-plan-date {
                    font-size: 12px;
                    color: $sunshine;
                }
            }

            .meals-for-day-container {
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                .meal-type-container {
                    display: flex;
                    flex-direction: column;
                    padding-left: 8px;

                    .meal-type-header {
                        font-size: 12px;
                        color: $default;
                        margin-bottom: 4px;
                    }
                }

                .meals-container {
                    padding-left: 16px;
                }

                .add-meal-item-btn {
                    padding-left: 16px;
                }
            }
            .meals-container {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                margin-bottom: 8px;
            }

            .meal-item-container {
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                column-gap: 8px;

                input[type="checkbox"] {
                    height: 20px;
                    width: 20px;
                }

                .meal-item-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 6px;
                }

                .meal-text-input-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    input[type="text"] {
                        background: transparent;
                        outline: none;
                        border: none;
                        color: $white;
                        width: calc(100% - 24px);
                        font-size: 16px;
                    }

                    label {
                        &.checked {
                            color: $default;
                            text-decoration: line-through;
                            font-size: 16px;
                            width: 100%;
                        }
                    }
                }

                .meal-item-date {
                    font-size: 12px;
                    color: $default;
                }

                .meal-item-meal-type {
                    font-size: 12px;
                    color: $light;
                }
            }

            .add-meal-item-btn {
                cursor: pointer;
                color: $primary-blue;
                font-size: 12px;
            }

            .edit-icon {
                height: 12px;
                cursor: pointer;
            }
        }

        .react-calendar {
            position: relative;
        }
    }

    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-left: -24px;
        padding: 0 24px;
        background-color: $aqua;

        .delete-buttons-container {
            display: flex;
            align-items: center;
            column-gap: 24px;
            @include down($mobile) {
                column-gap: 16px;
            }

            span {
                color: $default;
                font-size: 12px;
                @include down($mobile) {
                    font-size: 10px;
                }

                &.has-values {
                    color: $primary-blue;
                    cursor: pointer;
                }
            }
        }
    }

    .dropdown-container {
        padding: 4px 8px;
        min-width: 80px;
        .dropdown-options-container {
            &.open-above {
                top: -62px;
            }
        }

        .dropdown-label {
            font-size: 12px;
        }

        li {
            list-style: none;
            padding: 8px;
            cursor: pointer;
            font-size: 12px;
            &:hover {
                background: $light;
                color: $aqua;
            }
        }

        .icon {
            height: 6px;
            width: 10px;
        }
    }
}

.delete-grocery-item-modal,
.edit-ingredient-modal,
.delete-meal-plan-modal,
.edit-meal-plan-modal, 
.apply-to-ingredients-in-planned-meal-modal, 
.settings-modal {
    position: absolute;
    top: 50vh;
    left: 50%;
    z-index: 5;
    padding: 24px;
    background: $dark-aqua;
    transform: translate(-50%, -50%);
    width: unquote("min(calc(100% - 16px), 600px)");

    &.edit-ingredient-modal {
        height: 360px;
    }

    &.edit-meal-plan-modal {
        height: 310px;
    }

    &.edit-ingredient-modal,
    &.edit-meal-plan-modal {
        top: 0;
        transform: translate(-50%, 40%);
        padding: 24px 16px;
        .modal-content {
            justify-content: space-between;
            height: 100%;
            row-gap: 0;

            .top-container {
                display: flex;
                flex-direction: column;
                row-gap: 16px;
            }
            .modal-body {
                display: flex;
                flex-direction: column;
                row-gap: 16px;

                .edit-recipe-date-range-container {
                    display: flex;
                    column-gap: 12px;
                    position: relative;
                    & > div {
                        width: 50%;
                        position: unset;
                    }
                } 

                .edit-ingredient-input,
                .edit-recipe-name-input,
                .edit-recipe-date-input {
                    width: 100%;
                    padding: 8px 12px;
                    outline: none;
                    border: 1.5px solid $default;
                    background: $aqua;
                    color: $white;
                    font-size: 12px;
                    &::placeholder {
                        color: $default;
                    }
                    &::-ms-input-placeholder {
                        color: $default;
                    }
                }
            }
        }
    }

    &.settings-modal {
        .modal-content {
            row-gap: 0;

            .modal-header {
                h3 {
                    font-weight: 600;
                }
            }
        }

        .modal-body {
            margin: 24px 0 32px;

            .section-heading {
                color: $default;
                font-weight: 400;
                font-size: 12px;
            }
        }

        .starting-day-checklist {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            .starting-day-option {
                list-style: none;
                border: 1px solid $light;
                padding: 4px 8px;
                cursor: pointer;
                border-radius: 8px;
                color: $light;
                &.selected-day {
                    color: $sunshine;
                    border: 1px solid $sunshine;
                    border-radius: 8px;
                }
            }
        }
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        row-gap: 40px;

        .modal-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            column-gap: 16px;
            h3 {
                font-size: 16px;
                font-weight: 400;
            }
            img {
                width: 19px;
                height: 19px;
                cursor: pointer;
                padding: 1.5px;
            }
        }

        .edit-recipe-date-input {
            position: relative;
            .edit-recipe-date-label-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .edit-recipe-date-label {
                display: inline-block;
                width: 100%;
                cursor: pointer;
                &.is-default {
                    color: $default;
                }
            }
            .delete-date-btn {
                height: 12px;
                cursor: pointer;
            }
        }
    }
}

.category-dropdown-container {
    position: relative;

    .selected-category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1.5px solid $default;
        padding: 6px 12px;
        background: $aqua;
        font-size: 12px;
        height: 33px;
        cursor: pointer;

        .chevron-arrow {
            height: 10px;
            width: 14px;
            transition: transform 0.3s ease;
            &.is-open {
                transform: rotate(180deg);
            }
        }
    }

    .category-selector {
        display: none;
        position: absolute;
        max-height: 150px;
        overflow-y: auto;
        width: 100%;

        &.is-open {
            display: block;
            z-index: 1;
        }

        .category-selector-item {
            padding: 6px 12px;
            background: $aqua;
            border: 1.5px solid $default;
            cursor: pointer;
            border-top: none;
            font-size: 12px;
        }
    }
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    .react-calendar__navigation {
        height: 24px;
        margin-bottom: 0;
        button {
            font-size: 12px;
        }
    }
    .react-calendar__viewContainer {
        .react-calendar__month-view__weekdays__weekday {
            padding: 4px 8px;
            abbr {
                font-size: 8px;
                text-decoration: none;
                color: $black;
            }
        }
    }
    .react-calendar__month-view__days__day {
        padding: 8px;
        &:not(:disabled) {
            color: $aqua;
        }
        abbr {
            font-size: 10px;
        }
    }
}
