.music-player-overlay, .music-table-overlay {
    width: 100%;
    height: 100%;
    background: $overlay;
    backdrop-filter: blur(16px);
    position: absolute;
    top: 0;
    z-index: 4;
    display: none;
    cursor: pointer;
    max-height: 100vh;
    margin-left: -80px;
    &.show {
        display: block;
    }
}

.overlay {
    width: 100%;
    height: 100vh;
    background: $overlay;
    backdrop-filter: blur(16px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: none;
    cursor: pointer;
    &.opened {
        display: block;
    }

    &.layered-opened {
        z-index: 4;
        display: block;
    }

    @media only screen and (hover: none) and (pointer: coarse) {
        height: calc(100vh - 108px);
    }
}

@keyframes in {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}

@keyframes out {
    from {
        left: 0;
    }
    to {
        left: -100%;
        display: none;
    }
}