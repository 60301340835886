.item-container {
    display: flex;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 16px;
    text-decoration: none;
    &.clickable {
        cursor: pointer;
    }
    &:last-of-type {
        margin-bottom: 0px;
    }
    @include up($desktop) {
        &:hover {
            background-color: $hover;
            opacity: 0.85;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 0.15s;

            .item-date-range {
                color: $white;
            }

            .bullets {
                .bullet-item {
                    color: $white;
                }
            }

            .item-title,
            .item-organization {
                color: $primary-blue;

                .hosted-badge {
                    color: $white;
                }
            }
        }
    }

    @include down($desktop) {
        margin-left: 0;
        margin-bottom: 80px;
        padding: 0;
        &:first-of-type {
            margin-top: 32px;
        }
    }

    @include down($tablet) {
        flex-direction: column;
    }

    .item-image {
        @include up($mid-desktop) {
            width: 200px;
            height: 200px;
            min-width: 200px;
        }
        width: 175px;
        height: 175px;
        min-width: 175px;
        margin-right: 24px;
        border-radius: 4px;
        @include down($desktop) {
            width: 200px;
            height: 200px;
            min-width: 200px;
        }
        @include down($tablet) {
            width: unset;
            min-width: unset;
            height: 250px;
            margin-bottom: 24px;
            margin-right: 0;
            max-width: 400px;
        }

        &.image-border {
            border: 1px solid $white;
        }
    }

    .item-end-date {
        font-size: 14px;
        color: $default;
        min-width: 100px;
        @include down($tablet) {
            margin-bottom: 8px;
        }
    }

    .item-date-range {
        font-size: 14px;
        color: $default;
        min-width: 170px;
        @include down($desktop) {
            width: 200px;
            min-width: 200px;
            margin-right: 24px;
        }
        @include down($tablet) {
            min-width: unset;
            margin-bottom: 8px;
            margin-right: 0;
        }
    }

    .item-organization {
        color: $white;
        font-size: 14px;
        margin-bottom: 4px;
        display: flex;
        @include down($desktop) {
            color: $primary-blue;
        }

        .hosted-badge {
            margin-left: 8px;
            font-size: 10px;
            color: $primary-blue;
            background: $chip;
            padding: 2px 4px;
            border-radius: 2px;
            font-weight: 400;
        }
    }

    .item-title {
        color: $white;
        font-size: 14px;
        margin-bottom: 8px;
        @include down($desktop) {
            color: $primary-blue;
        }
    }

    .bullets {
        .bullet-item {
            font-size: 14px;
            margin-bottom: 12px;
            color: $default;
            list-style: none;
            line-height: 1.3;
            &:last-of-type {
                margin-bottom: 8px;
            }

            @include down($desktop) {
                color: $white;
            }
        }
    }

    .chips {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        .chip-item {
            padding: 8px 12px;
            margin-top: 8px;
            margin-right: 8px;
            background: $chip;
            border-radius: 16px;
            font-size: 12px;
            color: $primary-blue;
            font-weight: 600;
        }
    }
}

.show-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-top: 32px;

    .show-label {
        color: $default;
        &:hover {
            color: $white;
        }
    }
    .show-arrow {
        margin-left: 8px;
    }
}
