.recommendations {
    overflow-x: unset;
    .categories {
        margin-bottom: 20px;
        display: flex;
        overflow-x: auto;
        position: sticky;
        top: 0;
        background: $white;
        transition: margin-left 0.25s ease-in-out;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
        }

        &.scrolled-categories {
            width: 100vw;
            margin-left: -80px;
            @include down($tablet) {
                margin-left: -56px;
            }
            @include down($mobile) {
                margin-left: -24px;
            }
        }

        .category {
            padding: 8px 16px 4px;
            min-width: fit-content;
            color: $light;
            border-bottom: 6px solid transparent;
            cursor: pointer;
            &.selected {
                color: $default;
                border-bottom: 6px solid $default;
            }
        }
    }

    .recommendation-category-container {
        max-width: unquote("min(650px, 100%)");
        margin: 0 auto;

        .recommendation-category {
            margin-bottom: 16px;
            font-size: 20px;
        }

        .reviews-container,
        .recommendations-container {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            margin-bottom: 40px;

            .review,
            .recommendation {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                .review-header,
                .recommendation-header {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    column-gap: 8px;
                    width: 100%;
                    row-gap: 6px;

                    @include down($small-mobile) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .recommendation-title-container,
                    .review-title-and-subtitle-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        column-gap: 6px;
                        row-gap: 6px;
                        flex-wrap: wrap;
                    }

                    .review-title,
                    .recommendation-title {
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        font-weight: 400;
                        font-size: 16px;
                        color: $primary-blue;
                    }

                    .review-subtitle,
                    .recommendation-subtitle {
                        font-size: 12px;
                    }
                }

                .review-date {
                    font-size: 12px;
                }

                .review-text {
                    font-size: 14px;
                    color: $light;
                }

                .rating-container {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    
                    .review-rating {
                        font-size: 24px;
                    }

                    .review-in-progress {
                        font-size: 14px;
                        color: $light;
                    }
                }

                .expand-text {
                    font-size: 12px;
                    color: $sunshine;
                    cursor: pointer;
                }

                .recommendation-source-container {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    justify-content: space-between;
                    row-gap: 6px;
                }

                .recommendation-source {
                    font-size: 12px;
                    color: $light;
                }
            }

            .expanded-review-container {
                border: 2px double $light;
                padding: 12px;
                margin-top: -12px;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                row-gap: 40px;

                .expanded-review-item {
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;

                    p {
                        font-size: 12px;
                    }
                }

                .review-subheader {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    row-gap: 6px;
                    column-gap: 8px;
                }

                .review-source {
                    font-size: 12px;
                    color: $light;
                }

                .review-additional-text {
                    font-size: 12px;
                    color: $default;
                }
            }
        }

        .review-rating-group-container {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            margin-top: 24px;
            .review-rating-group {
                display: flex;
                align-items: center;
                column-gap: 8px;
                .review-rating-type {
                    font-size: 14px;
                }
                .review-rating {
                    font-size: 14px;
                }
            }
        }
    }
}
