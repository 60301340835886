.recipes {
    padding: 80px 0;
    @include down($tablet) {
        padding: 56px 0;
    }

    .heading-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        padding-left: 56px;
        padding-right: 56px;
        margin-bottom: 24px;
        @include down($mobile) {
            padding-left: 24px;
            padding-right: 24px;
        }

        .heading {
            margin-bottom: 0;
        }
    }

    .list-img {
        height: 24px;
        cursor: pointer;
    }

    .filter-chips-container {
        padding: 48px 12px 24px;
        margin: 0 4px;
        &.hidden-filters {
            padding-bottom: 56px;
        }

        .filtered-by-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .x-img {
                height: 16px;
                width: 16px;
                transform: rotate(45deg);
                animation: close-rotate 0.35s ease-in-out;
                cursor: pointer;
        
                &.rotate {
                    transform: rotate(180deg);
                    animation: open-rotate 0.35s ease-in-out;
                }

                @keyframes open-rotate {
                    0% {
                        transform: rotate(45deg);
                    }
                    100% {
                        transform: rotate(180deg);
                    }
                }
                
                @keyframes close-rotate {
                    0% {
                        transform: rotate(180deg);
                    }
                    100% {
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .filter-chips {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            &.show {
                min-height: 35px;
                transition: min-height 0.3s ease-in-out;
                @include down($mobile) {
                    min-height: 27.5px;
                }
            }
        }

        .chip {
            background: $hover;
            padding: 8px 12px;
            border-radius: 4px;
            color: $default;
            display: flex;
            align-items: center;
            gap: 8px 6px;
            &.non-chip {
                width: fit-content;
            }
            &:not(.non-chip) {
                cursor: pointer;
                &:hover {
                    background: $default;
                    color: $hover;
                }
            }
            @include down($mobile) {
                font-size: 10px;
            }

            img {
                width: 10px;
                height: 10px;
                @include down($mobile) {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }

    .greeting-container {
        background: $tan;
        padding: 32px 16px 20px;
        border-radius: 24px 24px 0 0px;
        margin: 0 4px;

        .greeting-text {
            color: $burnt;
            margin-bottom: 6px;
            font-weight: 600;
        }

        .name-text {
            color: $aqua;
            font-size: 40px;
            border: none;
            background: none;
            cursor: pointer;
            &:focus-visible {
                outline: none;
            }
        }
    }

    .search-and-filter-visible-toggle-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 24px 12px;
        margin: 0 4px;
        transition: display 0.3s ease-in-out;

        &.hide {
            display: none;
        }
    }

    .menu-filters-container {
        display: none;
        transition: display 0.3s ease-in-out;
        &.show {
            display: block;
        }

        .menu-filter-container {
            margin: 0 4px;
            padding: 10px 12px;

            &:last-of-type {
                padding-bottom: 56px;
            }

            .menu-type {
                margin-bottom: 16px;
            }

            .menu-list {
                display: flex;
                column-gap: 16px;
                overflow-x: auto;
            }

            .menu-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;

                .menu-image {
                    width: 100px;
                    height: 100px;
                    margin-bottom: 8px;
                    border-radius: 16px;
                    opacity: 0.45;
                    background: $grey;
                    transition: opacity 0.3s ease-in-out;
                    @include up($desktop) {
                        &:hover {
                            opacity: 1;
                            background: $grey !important;
                        }
                    }

                    &.includes-menu-item {
                        opacity: 1;
                        background: none;
                        @include up($desktop) {
                            &:hover {
                                opacity: 0.45;
                                background: $grey !important;
                            }
                        }
                    }

                    &.includes-menu-item-with-no-image {
                        opacity: 1;
                        background: $hover;
                        @include up($desktop) {
                            &:hover {
                                opacity: 0.45;
                                background: $grey !important;
                            }
                        }
                    }
                }

                .menu-label {
                    font-size: 10px;
                    text-align: center;
                }
            }
        }
    }

    .recipes-grouped-container {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        &:not(:last-of-type) {
            margin-bottom: 40px;
        }

        h4 {
            padding-left: 8px;
            font-size: 28px;
            font-weight: 300;
            color: $primary-blue;
            letter-spacing: 2px;
        }
    }

    .recipe-items-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 4px;
        .recipe-item-container {
            aspect-ratio: 1/1;
            cursor: pointer;
            z-index: 1;
            position: relative;
            width: 25%;
            padding: 4px;
            &:hover {
                opacity: 0.7;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 0.15s;
                border-radius: 4px;
                .recipe-image {
                    background: $hover;
                }
                .empty-recipe-image {
                    background: $default;
                }
            }

            @include down($tablet) {
                width: 33.33%;
            }
            @include down($mobile-xl) {
                width: 50%;
            }

            .recipe-image {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $black;
                background: $aqua;
                &.empty-recipe-image {
                    background: $default;
                }

                & > p {
                    max-width: 90%;
                    text-align: center;
                    color: $white;
                    text-shadow: 1px 1px 1px $black;
                    letter-spacing: 1.5px;
                    font-size: 18px;
                    @include down($mid-desktop) {
                        font-size: 16px;
                    }
                    @include down($desktop) {
                        font-size: 14px;
                    }
                    @include down($large-tablet) {
                        font-size: 12px;
                    }
                    @include down($mobile) {
                        font-size: 10px;
                    }
                    @include down($small-mobile) {
                        font-size: 8px;
                    }
                }
            }

            .recipe-item-title-container {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, -12px);
                background: $black;
                opacity: 0.65;
                width: calc(100% - 8px);
                padding: 12px 8px;
                display: flex;
                justify-content: center;

                .recipe-item-title {
                    color: $white;
                    font-weight: 600;
                    text-align: center;
                    text-shadow: 1px 1px 1px $black;
                    letter-spacing: 1.5px;
                    display: inline-block;
                    word-wrap: unset;
                    font-size: 20px;
                    z-index: 1;
                    @include down($xx-large) {
                        font-size: 18px;
                    }
                    @include down($extra-large) {
                        font-size: 16px;
                    }
                    @include down($mid-desktop) {
                        font-size: 12px;
                    }
                    @include down($desktop) {
                        font-size: 10px;
                    }
                    @include down($large-tablet) {
                        font-size: 8px;
                    }
                    @include down($tablet) {
                        font-size: 12px;
                    }
                    @include down(700px) {
                        font-size: 10px;
                    }
                    @include down($mobile-xl) {
                        font-size: 14px;
                    }
                    @include down($mobile) {
                        font-size: 10px;
                    }
                    @include down($small-mobile) {
                        font-size: 8px;
                    }
                    @include down($extra-small) {
                        font-size: 6px;
                    }
                }
            }

            .recipe-item-ingredients-count-container,
            .recipe-item-ingredients-overlap-count-container {
                position: absolute;
                top: 0;
                left: 0;
                background: $black;
                color: $white;
                padding: 4px;
                border-radius: 4px;
                transform: translate(8px, 8px);
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include down($mobile) {
                    font-size: 10px;
                }
            }

            .recipe-item-recommended-container {
                position: absolute;
                top: 0;
                right: 0;
                padding: 8px;

                .heart {
                    font-size: 22px;
                    &:before {
                        text-shadow: 0px 0px 10px $coral;
                        opacity: 0.75;
                    }
                }
            }

            .recipe-item-wip-container {
                position: absolute;
                top: 0;
                right: 0;
                padding: 6px 10px;

                .wip {
                    font-size: 20px;
                    &:before {
                        text-shadow: 0px 0px 10px $darkgrey;
                        opacity: 0.85;
                    }
                }
            }
        }
    }

    .heart {
        &:before {
            content: "\2665";
            color: $red;
        }
    }

    .wip {
        &:before {
            content: "🥣";
        }
    }

    .legend-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
        margin-top: 40px;

        .legend {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            min-width: 260px;
        }

        .legend-items {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
        }

        .chef-recommended-container,
        .chef-wip-container {
            display: flex;
            column-gap: 8px;
        }

        .recipes-sort-filter-container {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            min-width: 260px;

            .dropdown-container {
                min-width: 260px;

                .dropdown-options-container {
                    left: -1px;
                    min-width: 260px;
                    li {
                        padding: 4px 8px;
                        &:first-of-type {
                            padding-top: 8px;
                        }
                        &:last-of-type {
                            padding-bottom: 8px;
                        }
                    }
                }
            }
        }
    }

    .empty-recipe-container {
        display: flex;
        justify-content: center;
        margin-top: 100px;
    }

    .loader-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 100px;
        .watermelon {
            transform: unset;
            position: relative;
            left: unset;
            top: unset;
        }
    }

    .back-to-top-arrow {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background: $hover;
        padding: 12px 16px;
        color: $default;
        border-radius: 4px;
        z-index: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
            box-shadow: 1px 1px 10px $hover;
        }

        img {
            width: 36px;
            height: 36px;
            margin-bottom: 6px;
        }

        span {
            font-size: 8px;
            color: $white;
        }
    }

    .modal-container {
        .modal-tray {
            .modal-content {
                height: 100%;
                .modal-body {
                    height: calc(100vh - 109px);
                    .filters-container {
                        height: 100%;
                        flex-wrap: unset;
                    }
                }
            }
        }
    }
}
