*, body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "system-ui", "Segoe UI", Roboto, "Helvetica Neue";
    letter-spacing: 1.2px;
}

.italics {
    font-family: $cursive;
    font-style: italic;
}

.title-case {
    text-transform: capitalize;
}

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.align-start {
    align-items: flex-start;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
}

.justify-btwn {
    display: flex;
    justify-content: space-between;
}

.justify-around {
    display: flex;
    justify-content: space-around;
}

.justify-evn {
    display: flex;
    justify-content: space-evenly;
}

.justify-ctr {
    display: flex;
    justify-content: center;
}

.align-ctr {
    display: flex;
    align-items: center;
}

.flex-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
}

.set-top {
    margin-top: 100px;
}

.pointer {
    cursor: pointer;
}

.none {
    display: none;
}

.p-rel {
    position: relative;
}

.p-abs {
    position: absolute;
}

.Toastify__toast-container--top-right {
    position: fixed;
    top: 120px;
    .Toastify__toast {
        &.error {
            background: #ffbbbb;
        }
        &.success {
            background: #9fffba;
        }
        .Toastify__toast-body {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            text-align: center;
            color: black;
        }
        .Toastify__close-button {
            font-size: 16px;
        }
    }
}

.fade-in {
    animation: fadein 0.1s ease-in-out;
    opacity: 1;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.page {
    background-color: $aqua;
    color: $white;
    display: flex;
    justify-content: space-between;
    height: 100vh;
    @include down($desktop) {
        padding: 80px 32px;
        flex-direction: column;
        height: auto;
    }

    .heading-container {
        position: relative;
        z-index: 1;

        .back-btn {
            cursor: pointer;
            color: $primary-blue;
            margin-bottom: 8px;
            display: inline-block;
            &:hover {
                color: $primary-blue;
            }
        }

        .heading {
            margin-bottom: 40px;
        }
    }
}

.cursor {
    position: fixed;
    transform: translate(-50%, -50%);
    transition: transform 0.5s ease-out;
    &:before {
        content: "";
        display: block;
        width: 800px;
        height: 800px;
        border-radius: 50%;
        background: rgba(240, 234, 214, 0.5);
        opacity: 0.01;
        pointer-events: none;
    }

    @include down($desktop) {
        display: none;
    }
}
