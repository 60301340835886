.recipe-image-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .recipe-image-modal {
        position: absolute;
        top: 50vh;
        left: 50%;
        z-index: 5;
        transform: translate(-50%, calc(-50% - 32px));
        width: unquote("min(calc(100% - 16px), 600px)");
        display: none;

        &.opened {
            display: block;
        }
        &.closed {
            display: none;
        }

        .recipe-image-modal-image {
            height: auto;
            width: unquote("max(100%, 320px)");
        }

        .close {
            position: absolute;
            right: 16px;
            top: 16px;
            background: none;
            border: none;
            color: $white;
            cursor: pointer;
    
            img {
                width: 20px;
                height: 20px;
            }
        }

        .arrows-container {
            display: flex;
            width: 100%;
            justify-content: space-around;
            position: absolute;
            left: 50%;
            bottom: 16px;
            transform: translateX(-50%);

            .arrow-btn {
                background: transparent;
                border: none;
                outline: none;
                padding: 8px;
                cursor: pointer;
                &:disabled {
                    cursor: not-allowed;
                }

                .disabled-arrow {
                    transform: rotate(180deg);
                    height: 28px;
                    width: 20px;

                    &.next {
                        transform: rotate(0deg);
                    }
                }

                .prev-arrow {
                    transform: rotate(90deg);
                }

                .next-arrow {
                    transform: rotate(270deg);
                }

                img {
                    height: 20px;
                    width: 28px;
                }
            }
        }
    }

    .overlay {
        &.opened {
            display: block;
        }
    }
}
