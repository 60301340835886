.email-recipe-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;

    .share-a-recipe-label {
        font-size: 16px;
        font-weight: 500;
    }
    
    .email-recipe-form {
        width: unquote("min(calc(100% - 16px), 700px)");
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 12px 0 40px;
    
        .form-field {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;
            &:last-of-type {
                margin-bottom: 32px;
            }
    
            span {
                margin-bottom: 8px;
                font-size: 14px;
                @include down($desktop) {
                    margin-bottom: 4px;
                }
            }
    
            input, textarea {
                padding: 12px;
                border: none;
                border: 1px solid $default;
                border-radius: 4px;
                font-size: 16px;
                background: $white;
                outline: none;
                cursor: pointer;
                color: $black;
                @include down($desktop) {
                    padding: 8px;
                }
            }
            textarea {
                max-height: 250px;
                min-height: 250px;
                max-width: 100%;
                min-width: 100%;
                @include down($desktop) {
                    max-height: 200px;
                    min-height: 200px;
                }
            }
    
            .error-message {
                color: $sunshine;
                margin-top: 8px;
                display: block;
            }
        }
        .submit-btn {
            cursor: pointer;
            border: none;
            background-color: transparent;
            text-transform: uppercase;
            letter-spacing: 0.75px;
            color: $white;
        }
    }
}