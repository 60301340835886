.right-panel-container {
    @include up($desktop) {
        width: calc(100% - 320px);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 2;
        padding: 0 80px 0 32px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    @include down($desktop) {
        width: 100%;
        overflow-y: unset;
        height: auto;
        padding: 0;
    }

    .section-container {
        margin-bottom: 96px;
        @include up($desktop) {
            width: 550px;
        }
        @include up($mid-desktop) {
            width: 700px;
        }
        @include down($desktop) {
            width: 100%;
        }

        &.about-container {
            margin-top: 64px;
            @include down($desktop) {
                margin-top: 0;
            }

            .label {
                display: none;
                @include down($desktop) {
                    display: block;
                }
            }
            p {
                padding-left: 24px;
                color: $default;
                line-height: 1.35;
                @include down($desktop) {
                    padding-left: 0;
                    &:first-of-type {
                        margin-top: 32px;
                    }
                }

                &.passion,
                &.interests {
                    margin-bottom: 24px;
                }

                span {
                    color: $white;
                    font-weight: 600;
                }
            }
        }

        &.contact-container {
            margin-bottom: 64px;
        }

        .label {
            padding-left: 24px;
            margin-bottom: 16px;
            @include down($desktop) {
                padding: 16px 0 16px 32px;
                margin-bottom: 32px;
                font-size: 14px;
                position: sticky;
                top: -1px;
                background: $aqua;
                margin-bottom: 0;
                width: 100vw;
                margin-left: -32px;
            }
        }

        @import 'Items';
        @import 'Contact';
    }
}