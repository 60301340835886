$turquoise: #608b91;
$sky: #cdf0eb;
$pearl: white;
$silver: silver;

.kronos {
    height: 100vh;
    width: 100vw;
    background: $silver;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .fade-in;
    @include down($mobile-xl) {
        height: unset;
        min-height: 100vh;
    }
    .kronos-screen {
        background: $turquoise;
        width: 850px;
        height: 365px;
        @media (max-width: 875px) {
            width: 750px;
        }
        @include down($tablet) {
            width: 585px;
            height: 365px;
        }
        @include down($mobile-xl) {
            flex-direction: column;
            height: unset;
            width: 80%;
            margin-top: 100px;
            margin-bottom: 100px;
            &.password-container, &.screen-options {
                height: 400px;
                justify-content: center;
            }
        }
        @include down($mobile) {
            margin-top: 75px;
            margin-bottom: 75px;
            width: 90%;
        }
        .input-container {
            width: 100%;
            height: 60px;
            background: $sky;
            .password-input {
                width: 400px;
                font-size: 40px;
                text-transform: uppercase;
                letter-spacing: 0.25em;
                background-color: transparent;
                border: none;
                outline: none;
                display: flex;
                justify-content: center;
                text-align: center;
                z-index: 1;
                &::-moz-placeholder, &::placeholder {
                    color: black;
                }
                @include down($mobile) {
                    font-size: 28px;
                }
            }
            .password-label {
                font-size: 40px;
                text-transform: uppercase;
                letter-spacing: 0.25em;
                z-index: 1;
                display: flex;
                align-items: center;
                @include down($mobile) {
                    font-size: 28px;
                }
            }
            .last-letter {
                letter-spacing: 0em;
                animation: flashing-letter 1s infinite ease-in-out;
                border-bottom: 2px solid black;
                height: 40px;
                width: 24px;
            }
            @keyframes flashing-letter {
                0% {
                    border-bottom: 2px solid black;
                }
                50% {
                    border-bottom: none;
                }
                100% {
                    border-bottom: 2px solid black;
                }
            }
        }

        .kronos-program-options {
            background: $sky;
            width: 100%;
            justify-content: center;
            ul {
                list-style-type: none;
                width: 305px;
                margin-right: auto;
                margin-left: auto;
                @include down($mobile) {
                    width: 250px;
                }
                li {
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    text-transform: uppercase;
                    letter-spacing: 1.3px;
                    cursor: pointer;
                    padding: 6px 8px;
                    outline: none;  
                    @include down($mobile) {
                        font-size: 12px;
                    }
                    &:hover, &:focus {
                        background: $silver;

                    }
                    #island, #supers, #omnidroid, #finance {
                        height: 20px;
                        width: 20px;
                        margin-right: 32px;
                        @include down($mobile-xl) {
                            margin-right: 24px;
                        }
                    }
                    &:last-of-type {
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .opponent-container,
        .prototype-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            position: relative;
            &.opponent-container {
                border-right: 0.25px solid $pearl;
            }
            @include down($mobile-xl) {
                width: 100%;
                &.opponent-container {
                    border-right: 0;
                    padding-bottom: 24px;
                    border-bottom: 0.25px solid $pearl;
                }
                &.prototype-container {
                    margin-bottom: 24px;
                }
            }
            .superhero-image,
            .prototype-image {
                width: 100%;
                max-height: 216px;
                min-height: 216px;
                margin-bottom: 12px;
            }
            .opponent-top-banner,
            .prototype-top-banner {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding: 8px;
            }
            .opponent-label,
            .prototype-label {
                text-transform: uppercase;
                font-size: 18px;
                letter-spacing: 1.1px;
                @include down($mobile) {
                    font-size: 16px;
                }
            }
            .threat-rating {
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1.2px;
                @include down($mobile) {
                    font-size: 10px;
                }
            }
            .threat-rating,
            .superhero-info-container,
            .prototype-info-container {
                color: $pearl;
            }
            .superhero-name,
            .prototype-name {
                text-align: center;
                margin-bottom: 8px;
                text-transform: uppercase;
                letter-spacing: 1.3px;
                font-size: 16px;
                .version-label {
                    text-transform: lowercase;
                }
                @include down($mobile) {
                    font-size: 14px;
                    margin-bottom: 4px;
                }
            }
            .superhero-power-details,
            .prototype-power-details {
                text-align: center;
                padding-left: 16px;
                padding-right: 16px;
                font-size: 14px;
                text-transform: uppercase;
                @include down($mobile) {
                    font-size: 12px;
                    letter-spacing: 1.1px;
                    padding-left: 11px;
                    padding-right: 11px;
                }
            }
            .termination {
                position: absolute;
                top: 50%;
                transform: translate(0, -100%);
                background-color: red;
                width: 100%;
                text-align: center;
                color: $pearl;
                letter-spacing: 1.4px;
                text-transform: uppercase;
                font-size: 48px;
                font-weight: 600;
                font-family: sans-serif;
                &.visible-none {
                    visibility: hidden;
                }
                @include down($tablet) {
                    font-size: 40px;
                }
            }
        }
    }
}
