.selector {
    .items-and-rotator {
        position: relative;
        min-height: 450px;
        min-width: 200px;
        display: flex;
        width: 80%;
        justify-content: space-between;
        max-width: 800px;
        @include down($desktop) {
            flex-direction: column;
            align-items: center;
            width: unset;
        }
        &.bottom-section {
            margin-bottom: 100px;
            width: 100%;
            justify-content: center;
        }
        .item-list-container {
            width: 300px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 100px;
            margin-bottom: 24px;
            @include down($desktop) {
                margin-bottom: 56px;
                margin-right: unset;
            }
            .list-label {
                display: block;
                margin-bottom: 8px;
                font-size: 13px;
                font-weight: 600;
            }
            .input-and-button-container {
                margin-bottom: 6px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                input {
                    outline: none;
                    width: 254px;
                    height: 32px;
                    padding: 8px;
                    cursor: pointer;
                    letter-spacing: 1.25px;
                }
                button {
                    height: 32px;
                    width: 42px;
                    letter-spacing: 1.1px;
                    background: $sunshine;
                    color: white;
                    outline: none;
                    border: none;
                    cursor: pointer;
                }
            }
            .items-container {
                width: 100%;
                max-height: calc(100% - 150px);
                overflow-y: scroll;
                display: flex;
                flex-direction: column;
                align-items: center;
                .item-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px;
                    margin-bottom: 6px;
                    cursor: pointer;
                    width: 95%;
                    color: $black;
                    &:hover {
                        transform: scale(1.05);
                    }
                    &:nth-of-type(2n - 1) {
                        background: lightgreen;
                    }
                    &:nth-of-type(2n) {
                        background: $primary-blue;
                    }
                    li {
                        list-style-type: none;
                        font-size: 14px;
                        letter-spacing: 1.25px;
                    }
                    img {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }
        .rotator-container {
            @include down($desktop) {
                margin-bottom: 60px;
            }
        }
        .decider-container {
            display: flex;
            .item-list-container {
                width: 250px;
                .list-label {
                    margin-left: 7.5%;
                }
                .items-container {
                    max-height: unset;
                    .item-container {
                        width: 85%;
                        cursor: unset;
                    }
                }
            }
        }
    }

    .time-ranges-container {
        display: flex;
        justify-content: space-evenly;
        width: 300px;
        margin-bottom: 24px;
        .time-range-container {
            display: flex;
            flex-direction: column;
            .range-label {
                margin-bottom: 4px;
                display: block;
                margin-bottom: 8px;
                font-size: 13px;
                font-weight: 600;
            }
            select {
                cursor: pointer;
                padding: 6px;
            }
        }
    }

    .btn-container {
        display: flex;
        width: 250px;
        justify-content: center;
        &.space-between {
            justify-content: space-between;
        }
    }
    .resort-btn,
    .reveal-btn,
    .reset-btn {
        padding: 4px 8px;
        border-radius: 4px;
        outline: none;
        border: 1px solid $black;
        background: $sunshine;
        color: $white;
        cursor: pointer;
        &.disabled {
            cursor: unset;
        }
    }
    .resort-btn {
        margin-top: 16px;
    }
}
