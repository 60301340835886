.main {
    @import "./_variables";
    @import "./mixins";
    @import "./global";
    @import "./Dashboard";
    @import "./NonDashboardPage";
    @import "./Music";
    @import "./Overlay";
    // @import './ChordsModal';
    @import "./Kronos";
    @import "./Selector";
    @import "./Recipe";
    @import "./Recipes";
    @import "./EmailRecipeForm";
    @import "./GroceryListModal";
    @import "./Recommendations";
    @import "./Loader";
    @import "./Dropdown";
    @import "./Modal";
    @import "./SearchBar";
    @import "./Filter";
    @import "./ModalContainer";
    @import "./RecipeImageModal";
}