.watermelon {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 40px;
    border-radius: 0 0 100px 100px;
    border: 5px solid #538a2d;
    border-top: 0;
    box-sizing: border-box;
    background:
      radial-gradient(farthest-side at top,#0000 calc(100% - 5px),#e7ef9d calc(100% - 4px)), 
      radial-gradient(closest-side at 2px 3px,#5c4037 89%,#0000) 0 0/17px 12px,
      #ff1643;
    --c:radial-gradient(farthest-side,#000 94%,#0000);
    -webkit-mask:
      linear-gradient(#0000 0 0),
      var(--c) 12px -8px,
      var(--c) 29px -8px,
      var(--c) 45px -6px,
      var(--c) 22px -2px,
      var(--c) 34px  6px, 
      var(--c) 21px  6px,
      linear-gradient(#000 0 0);
    mask:
      linear-gradient(#000 0 0),
      var(--c) 12px -8px,
      var(--c) 29px -8px,
      var(--c) 45px -6px,
      var(--c) 22px -2px,
      var(--c) 34px  6px, 
      var(--c) 21px  6px,
      linear-gradient(#0000 0 0);
    -webkit-mask-composite:destination-out;
    mask-composite:exclude,add,add,add,add,add,add;
    mask-repeat: no-repeat;
    animation: l8 3s infinite;
  }
  @keyframes l8 {
   0%   {-webkit-mask-size: auto,0 0,0 0,0 0,0 0,0 0,0 0}
   15%  {-webkit-mask-size: auto,20px 20px,0 0,0 0,0 0,0 0,0 0}
   30%  {-webkit-mask-size: auto,20px 20px,20px 20px,0 0,0 0,0 0,0 0}
   45%  {-webkit-mask-size: auto,20px 20px,20px 20px,20px 20px,0 0,0 0,0 0}
   60%  {-webkit-mask-size: auto,20px 20px,20px 20px,20px 20px,20px 20px,0 0,0 0}
   75%  {-webkit-mask-size: auto,20px 20px,20px 20px,20px 20px,20px 20px,20px 20px,0 0}
   90%,
   100% {-webkit-mask-size: auto,20px 20px,20px 20px,20px 20px,20px 20px,20px 20px,20px 20px}
}

.avocado-container {
  width: 100vw;
  height: 5px;
  background: $primary-blue;
  position: relative;
  top: 250px;
  .avocado {
    position: absolute;
    width: 100px;
    height: 120px;
    bottom: 31px;
    left: 0;
    background: #616f1a;
    border-radius: 60% 60% 45% 54% / 66% 70% 45% 50%;
    box-shadow: 1px 1px 5px $white;
    animation: rotate 2s ease-in-out infinite;
  
    .skin {
      position: absolute;
      bottom: -1px;
      left: 17%;
      width: 80px;
      height: 110px;
      background: #e7f68b;
      border-radius: 60% 60% 45% 45% / 70% 70% 45% 45%;
      border: 10px solid #c8e26f;
    }
    .seed {
      position: absolute;
      bottom: 15px;
      left: 63%;
      transform: translate(-50%, -10%);
      width: 40px;
      height: 40px;
      background: #C57153;
      border-radius: 50%;
      border: 2px solid;
      border-color: #98602D #704F31 #704F31 #98602D;
    }
    .face {
      position: absolute;
      top: 25%;
      left: 54%;
      display: flex;
      width: 12px;
      justify-content: space-between;
      .eyes {
        height: 2px;
        width: 2px;
        background: #2B1E13;
        border-radius: 50%;
        &:first-of-type {
          margin-right: 3px;
        }
      }
      .mouth {
        position: absolute;
        top: 10%;
        left: 20%;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid;
        transform: rotate(45deg);
        border-color: transparent $black $black transparent;
      }
    }
    .left-arm {
      position: absolute;
      top: 35%;
      left: -17%;
      width: 50px;
      height: 1px;
      border-radius: 50%;
      border: 2px solid;
      transform: rotate(45deg);
      border-color: transparent $white $white transparent;
    }
    .right-arm {
      position: absolute;
      top: 15%;
      left: 110%;
      width: 1px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid;
      transform: rotate(45deg);
      border-color: transparent $white $white transparent;
    }
    .left-leg {
      position: absolute;
      top: 96%;
      left: 26%;
      width: 6px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid;
      transform: rotate(188deg);
      border-color: transparent $white $white transparent;
    }
    .right-leg {
      position: absolute;
      top: 96%;
      left: 90%;
      width: 50px;
      height: 1px;
      border-radius: 50%;
      border: 2px solid;
      transform: rotate(222deg);
      border-color: transparent $white $white transparent;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(13deg) translate(0%, 0%);
  }
  10% {
    transform: rotate(45deg) translate(21%, -44%);
  }
  20% {
    transform: rotate(77deg) translate(15%, -91%);
  }
  35% {
    transform: rotate(89deg) translate(5%, -108%);
  }
  40% {
    transform: rotate(114deg) translate(-66%, -125%);
  }
  45% {
    transform: rotate(132deg) translate(-117%, -119%);
  }
  50% {
    transform: rotate(164deg) translate(-200%, -73%);
  }
  60% {
    transform: rotate(184deg) translate(-233%, -25%);
  }
  70% {
    transform: rotate(250deg) translate(-108%, 247%);
  }
  85% {
    transform: rotate(300deg) translate(159%, 271%);
  }
  100% {
    transform: rotate(365deg) translate(431%, -39%);
  }
}

// .shadow {
//   width: 90%;
//   height: 15%;
//   background: $gray;
//   opacity: .7;
//   box-shadow: 2px 2px 10px rgba($gray, .3);
//   border-radius: 50%;
//   position: absolute;
//   z-index: -3;
//   bottom: -21%;
//   transform: rotate(1deg);
//   animation: scale 1.5s ease-in infinite;
// }

// @keyframes bounce {
//   50% {
//      transform: translateY(-5px);
//   }
// }

// @keyframes scale {
//   50% {
//     transform: scale(0.9);
//   }
// }

