.music {
    .music-table {
        position: relative;
        z-index: 1;
        margin-bottom: 175px;
        margin-top: 24px; 

        .row {
            display: flex;
            list-style: none;
            padding: 12px;
            cursor: pointer;
            max-width: 700px;
            &.row-labels {
                margin-bottom: 8px;
                position: sticky;
                top: 0;
                li {
                    color: $default;
                }
                &.sticky {
                    border-bottom: 1px solid $default;
                    background: $hover;
                }
            }
            &.row-item {
                &:hover {
                    background: $hover;
                    border-radius: 4px;
                }
            }

            .id {
                width: 24px;
                text-align: right;

                .id-number {
                    display: block;
                    &.selected-song {
                        color: $primary-blue;
                    }
                }

                .play-btn {
                    width: 16px;
                    height: 16px;
                    &.double-digits {
                        transform: translate(-1px, 0);
                    }
                    &.single-digit {
                        transform: translate(2px, 0);
                    }
                }
            }
            .song-name {
                width: 300px;
                padding-left: 32px;
                @include down($tablet) {
                    max-width: 275px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &.selected-song {
                    color: $primary-blue;
                }
            }
            .added-at {
                width: 125px;
                text-align: center;
                @include down($mobile-xl) {
                    display: none;
                }
            }
            .created-at {
                width: 100px;
                text-align: center;
                @include down($tablet) {
                    display: none;
                }
            }
            .lyrics {
                width: 75px;
                text-align: center;
            }
            .time {
                width: 100px;
                text-align: center;
            }
            .document-logo {
                width: 16px;
                height: 16px;
                margin-top: 2px;
            }
        }
    }

    .music-player {
        position: fixed;
        bottom: 0;
        padding: 40px 32px;
        background: $dark-aqua;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100vw;
        margin-left: -80px;
        @include down($tablet) {
            margin-left: -56px;
            flex-direction: column;
        }
        @include down($mobile-xl) {
            flex-direction: column;
            padding: 32px 16px;
        }
        @include down($mobile) {
            margin-left: -24px;
        }

        .song-detail-container {
            display: flex;
            flex-direction: column;
            min-width: 200px;
            @include down($tablet) {
                display: none;
            }

            .song-name {
                font-size: 16px;
                color: $white;
                margin-bottom: 12px;
            }

            .artist-name {
                font-size: 16px;
                color: $default;
            }
        }

        .interactions-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            .icon-buttons-container {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .logo-btn {
                    height: 24px;
                    width: 24px;
                    margin-right: 32px;
                    &:last-of-type {
                        margin-right: 0;
                    }
                    &:hover {
                        transform: scale(1.25);
                    }
                }

                .shuffle-logo,
                .repeat-logo {
                    height: 28px;
                    width: 28px;
                    &.selected-type {
                        color: $primary-blue;
                    }
                }
                .shuffle-btn {
                    margin-top: -1px;
                }
                .repeat-logo {
                    margin-top: 6px;
                }
            }
            .progress-bar-container {
                display: flex;
                align-items: center;

                .progress-time {
                    min-width: 40px;
                }

                &:hover {
                    .progress-bar {
                        position: relative;

                        .progress-bar-fill {
                            background: $primary-blue;
                        }

                        .progress-peg {
                            position: absolute;
                            top: 0;
                            transform: translate(-50%, -25%);
                            height: 18px;
                            width: 18px;
                            border-radius: 50%;
                            background: $white;
                        }
                    }
                }
                .progress-bar {
                    border-radius: 12px;
                    background: $default;
                    height: 6px;
                    width: 250px;
                    margin-left: 8px;
                    margin-right: 8px;
                    @include down($mobile-xl) {
                        width: 200px;
                    }

                    .progress-bar-fill {
                        border-radius: 12px;
                        background: $white;
                        height: 100%;
                    }
                }
            }
        }

        .supplementary-interactions-container {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            @include down($desktop) {
                display: none;
            }

            .logo-btn {
                height: 28px;
                width: 28px;
                margin-right: 24px;
                &:last-of-type {
                    margin-right: 16px;
                }
                &:hover {
                    transform: scale(1.25);
                }
            }

            .volume-container {
                height: 8px;
                width: 100px;
                background: $default;
                border-radius: 12px;
                position: relative;
                &:hover {
                    .volume-bar {
                        background: $primary-blue;
                    }

                    .volume-peg {
                        position: absolute;
                        top: 0;
                        transform: translate(25%, -25%);
                        height: 18px;
                        width: 18px;
                        border-radius: 50%;
                        background: $white;
                    }
                }

                .volume-bar {
                    border-radius: 12px;
                    background: $white;
                    height: 100%;
                }
            }
        }
    }

    .queue-modal,
    .lyrics-modal {
        position: absolute;
        background: $dark-aqua;
        width: 400px;
        top: 0;
        right: -400px;
        height: 100%;
        z-index: 5;
        border-left: solid $aqua 1px;
        display: none;
        overflow-y: hidden;
        max-height: 100vh;
        &.opened {
            right: 0;
            display: block;
            animation: slide-in 1s ease-in-out;
            transition: right 1s;
        }
        &.closed {
            right: -400px;
            animation: slide-out 1s ease-in-out;
            transition: right 1s;
            @include down($mobile) {
                right: -100%;
            }
        }

        @include down($mobile) {
            width: 100%;
            right: -100%;
        }

        .heading-container {
            display: flex;
            justify-content: space-between;
            padding: 24px 32px;
            @include down($mobile) {
                padding: 24px;
            }

            h2 {
                font-size: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: calc(100% - 40px);
            }

            .close-btn {
                cursor: pointer;
                background: none;
                border: none;
                outline: none;
                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .song-list-container {
            overflow-y: scroll;
            padding: 0 24px;
            max-height: calc(100% - 100px);

            .song-list-item {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                cursor: pointer;
                padding: 12px;
                border-radius: 4px;
                &.selected-song {
                    background: $hover;
                    .song-name,
                    .song-number {
                        color: $primary-blue;
                    }
                }
                &:hover {
                    background: $hover;
                    .song-name {
                        color: $primary-blue;
                    }
                }

                .play-btn {
                    height: 16px;
                    width: 16px;
                    &.double-digits {
                        transform: translate(-1px, 0);
                    }
                    &.single-digit {
                        transform: translate(2px, 0);
                    }
                }

                .song-number {
                    color: $white;
                    font-size: 20px;
                    min-width: 24px;
                    text-align: right;
                    margin-right: 24px;
                }

                .song-details {
                    display: flex;
                    flex-direction: column;

                    .song-name {
                        font-size: 16px;
                        margin-bottom: 4px;
                    }

                    .artist-name {
                        color: $default;
                    }
                }
            }
        }

        .lyrics-container {
            overflow-y: scroll;
            padding: 0 32px;
            max-height: calc(100% - 100px);
            @include down($mobile) {
                padding: 0 24px;
            }

            .music-card {
                padding-bottom: 50px;
                border-bottom: 3px solid $white;
                &:last-of-type {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                
                .capo {
                    display: block;
                    font-size: 12px;
                    margin-bottom: 8px;
                    @include down($mobile) {
                        font-size: 10px;
                    }
                }

                strong {
                    display: block;
                    margin-bottom: 28px;
                    &.intro-label,
                    &.outro-label {
                        margin-bottom: 12px;
                    }
                }
                .ind-chord {
                    font-size: 10px;
                    @include down($mobile) {
                        font-size: 8px;
                    }
                }
                p {
                    margin-bottom: 24px;
                    letter-spacing: 1px;
                    font-size: 12px;
                    .p-rel {
                        display: inline-block;
                        margin-bottom: 4px;
                    }
                    .p-abs {
                        top: -100%;
                        font-size: 10px;
                        letter-spacing: 0.5px;
                        &.beginning {
                            left: 0;
                        }
                        &.middle {
                            left: 50%;
                        }
                        &.ending {
                            left: 100%;
                            transform: translateX(-100%);
                            &.long {
                                transform: translateX(-10px);
                                @include down($mobile) {
                                    transform: translateX(-6px);
                                }
                            }
                        }
                    }
                    @include down($mobile) {
                        font-size: 10px;
                        .p-abs {
                            font-size: 8px;
                        }
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@keyframes slide-in {
    from {
        right: -400px;
        @include down($mobile) {
            right: -100%;
        }
    }
    to {
        right: 0;
    }
}

@keyframes slide-out {
    from {
        right: 0;
    }
    to {
        right: -400px;
        @include down($mobile) {
            right: -100%;
        }
    }
}
