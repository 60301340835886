.dropdown-container {
    position: relative;
    max-width: 250px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid $default;
    cursor: pointer;

    &.is-open {
        border-bottom: none;
        border-radius: 4px 4px 0 0;
        &.open-above {
            border-bottom: 1px solid $default;
            border-radius: 0 0 4px 4px;
        }
    }

    .dropdown-selector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
    }

    .chevron-arrow {
        transition: transform 0.3s ease;
        &.is-open {
            transform: rotate(180deg);
        }
        &.reverse {
            transform: rotate(180deg);
            &.is-open {
                transform: rotate(0deg);
            }
        }
    }

    .dropdown-options-container {
        position: absolute;
        left: 0;
        background: $aqua;
        width: 100%;
        max-width: 250px;
        border: 1px solid $default;
        box-shadow: 0 1px 10px $default;
        z-index: 2;

        li {
            list-style: none;
        }
    }

    .icon {
        height: 12px;
        width: 16px;

        &.partial {
            height: 2px;
            width: 12px;
        }
    }
}
