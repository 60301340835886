.search-bar {
    width: 200px;
    height: 40px;
    padding: 4px 16px;
    border: 1px solid $default;
    background: $default;
    color: $black;
    font-size: 20px;
    font-weight: 600;
    outline: none;
    transition: all 0.3s ease;
    border-radius: 20px;
    display: flex;
    column-gap: 4px;

    &.full-width {
        width: 100%;
    }

    &.contains-search,
    &.expandable:focus-within {
        width: 350px;
        transition: width 0.3s ease;
        @include down($tablet) {
            width: 300px;
        }
        @include down(700px) {
            width: 250px;
        }
        @include down(650px) {
            width: 225px;
        }
        @include down($mobile-xl) {
            width: 100%;
        }
    }
    @include down($mobile-xl) {
        width: 100%;
    }
    input {
        height: 32px;
        width: 100%;
        border: none;
        padding: 0 8px;
        outline: none;
        background: transparent;
        color: $white;
        letter-spacing: 2px;
        cursor: pointer;
        &::placeholder {
            color: $light;
        }
    }
    .clear-btn {
        visibility: hidden;
        cursor: pointer;
        background: transparent;
        border: none;
        padding: 4px;
        transition: visibility 0.3s ease-in-out; 
        &.contains-search {
            visibility: visible;
        }

        img {
            width: 16px;
            height: 16px;
        }
    }
}