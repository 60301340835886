.contact-container {
    padding-left: 24px;
    @include down($desktop) {
        padding-left: 0px;
    }

    .message-text {
        margin-bottom: 40px;
        font-size: 14px;
        @include down($desktop) {
            margin-bottom: 24px;
        }
    }

    .contact-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        .form-field {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;
            &:last-of-type {
                margin-bottom: 32px;
            }

            span {
                margin-bottom: 8px;
                font-size: 14px;
                @include down($desktop) {
                    margin-bottom: 4px;
                }
            }

            input, textarea {
                padding: 12px;
                border: none;
                border: 1px solid $default;
                border-radius: 4px;
                font-size: 16px;
                background: $white;
                outline: none;
                cursor: pointer;
                color: $black;
                @include down($desktop) {
                    padding: 8px;
                }
            }
            textarea {
                max-height: 100px;
                max-width: 100%;
                min-height: 100px;
                min-width: 100%;
                @include down($desktop) {
                    max-height: 85px;
                    min-height: 85px;
                }
            }

            .error-message {
                color: $sunshine;
                margin-top: 8px;
                display: block;
            }
        }
        .submit-btn {
            cursor: pointer;
            border: none;
            background-color: transparent;
            text-transform: uppercase;
            letter-spacing: 0.75px;
            color: $white;
        }
    }

    .me-or-company {
        width: 100%;
        height: fit-content;
        // margin-bottom: 40px;
        @include down($desktop) {
            width: 100%;
            height: auto;
        }
    }

    // .resume-links-container {
    //     display: flex;
    //     justify-content: space-evenly;
    //     width: 100%;
    //     min-width: 300px;
    // }

    // .resume-link {
    //     z-index: 1;
    //     cursor: pointer;
    //     padding: 8px 20px;
    //     max-width: 250px;
    //     color: white;
    //     border-radius: 16px;
    //     text-decoration: none;
    //     text-shadow: 1px 1px 10px black;
    //     background: lightgreen;
    //     outline: none;
    //     @media screen and (max-width: $tablet) {
    //         padding: 6px 15px;
    //         font-size: 14px;
    //     }
    //     &:hover {
    //         box-shadow: 1px 1px 8px 2px rgb(173, 251, 173);
    //     }
    //     &.downloadable-link {
    //         background: skyblue;
    //         &:hover {
    //             box-shadow: 1px 1px 8px 2px rgb(176, 221, 239);
    //         }
    //         &:focus {
    //             box-shadow: 1px 1px 8px 2px rgb(176, 221, 239);
    //         }
    //     }
    // }
}