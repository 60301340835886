.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .modal-tray {
        background: $aqua;
        position: absolute;
        top: 0;
        height: 100vh;
        width: unquote("min(95vw, 300px)");
        z-index: 3;
        left: -100%;
        padding: 24px;
        &.opened {
            left: 0;
            animation: in 0.75s ease-in-out;
        }
        &.closed {
            left: -100%;
            animation: out 0.75s ease-in-out;
        }

        @media only screen and (hover: none) and (pointer: coarse) {
            height: calc(100vh - 108px);
        }

        .filters-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 16px;
        }

        .filter-category-container {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .filter-category-label {
                font-size: 12px;
                font-weight: 400;
            }

            .dropdown-option {
                padding: 8px 16px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .modal-footer {
        position: absolute;
        bottom: 0;
        padding: 16px 0;
        background-color: $aqua;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 48px);

        .total-ratio {
            font-size: 32px;
        }

        .reset-all {
            font-size: 12px;
            color: $default;
            &.has-values {
                cursor: pointer;
                color: $primary-blue;
            }
        }
    }
}
